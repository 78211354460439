import React from 'react';
import { Icon, Modal, Spacing, Text } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfoText from '../atoms/InfoText';
import './ExchangeInfoModal.scss';

/**
 * ExchangeInfoModal Functional Component
 *
 * A modal component that displays information related to the ticket exchange process.
 *
 * This component is typically used to show detailed information or instructions about exchanging tickets
 * in a modal window.
 *
 * @returns {JSX.Element} The rendered modal component displaying exchange information.
 */
const ExchangeInfoModal = () => {
  const { t } = useTranslation('exchange');
  const { env } = useSelector((state) => state.whitelabelConfig);

  return (
    <Spacing size="XS" vertical>
      <Modal
        title={t(`modal_info.${env.brand}.title`)}
        modalTrigger={
          <>
            <Spacing size="XS" alignItems="baseline">
              <Icon type="CircleInfo" color="info" size="S" />
              <Text underline color="accent" weight="semibold" size="S">
                {env.brand === 'iamsa' && t('modal_info.label_iamsa')}
                {env.brand === 'satelitenorte' && t('modal_info.satelitenorte.label_trigger')}
                {t(`modal_info.${env.brand}.label_trigger`)}
              </Text>
            </Spacing>
          </>
        }
      >
        <Spacing vertical size="L">
          <Text>{t('modal_info.find_the_data')}</Text>
          <Spacing vertical size="S">
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              {t(`modal_info.${env.brand}.first`)}
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              <Trans t={t} i18nKey={`modal_info.${env.brand}.second`}>
                <b />
              </Trans>
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              {t(`modal_info.${env.brand}.third`)}
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              <Trans t={t} i18nKey={`modal_info.${env.brand}.fourth`}>
                <b />
              </Trans>
            </InfoText>
          </Spacing>
          <i className={`operation-${env.brand}`} />
        </Spacing>
      </Modal>
    </Spacing>
  );
};

export default ExchangeInfoModal;
