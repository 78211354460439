import {
  finishSeatSelection,
  deletePassenger,
  setPassengers,
  receiveTickets,
} from '@/actions/purchase';
import { seatChangeTracker } from '../../../metrics/user-analytics/purchase';
import wayAllowsSeatSelection from '../../../utils/purchase/wayAllowsSeatSelection';

// eslint-disable-next-line import/prefer-default-export
export const dispatchFinishSeatSelection = (history) => {
  return (dispatch, getState) => {
    const { purchase } = getState();
    const plainState = purchase.toJS();

    const { token, roundTrip } = plainState;
    const isDepartureRoute = history.location.pathname.includes('seats/departure');
    const allowsSeatSelection = wayAllowsSeatSelection(plainState, 'departs');

    let route = `/purchase/${token}/`;
    route += isDepartureRoute && roundTrip ? 'seats/return' : 'passengers';

    seatChangeTracker(plainState, isDepartureRoute ? 'departure' : 'return');
    dispatch(finishSeatSelection());

    if (allowsSeatSelection) history.push(route);
    else history.replace(route);
  };
};

export const dispatchResetPassengers = () => {
  return (dispatch, getState) => {
    const { purchase } = getState();
    const plainState = purchase.toJS();
    const { token, passengers } = plainState;

    passengers.forEach(({ id }) => {
      if (id) dispatch(deletePassenger(token, id));
    });
    dispatch(setPassengers([]));
    /**
     * This part of the code triggers with trips with connections every time DepartureSeats loads
     * This causes locked tickets to be overwritten in the state.
     */
    dispatch(receiveTickets('departs', []));
    dispatch(receiveTickets('returns', []));
  };
};
