import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Gap, Spacing, Text, Icon } from '@reservamos/elements';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { useSelector } from 'react-redux';
import LoadingDots from 'ui/atoms/LoadingDots';
import PricingRow from '../../ui/atoms/PricingRow';
import discountGFA from '../../images/brands-unique/gfa/early-discount.png';
import discountETN from '../../images/brands-unique/etn/discountETN.png';

const earlyDiscountIcons = {
  etn: discountETN,
  gfa: discountGFA,
  default: 'emojiSparkles',
};

const propTypes = {
  reservation: PropTypes.shape({
    tripSlug: PropTypes.string.isRequired,
    tickets: PropTypes.array.isRequired,
    pricing: PropTypes.shape({
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
    hasConnections: PropTypes.bool,
    hasDetails: PropTypes.bool,
    trips: PropTypes.array,
  }).isRequired,
  totalLabel: PropTypes.string.isRequired,
  externalCouponCode: PropTypes.string,
};

/**
 * Row component
 * @param {string} label - Label to show
 * @param {string} discountLabel - Discount label to show
 * @param {number} price - Price to show
 * @param {boolean} hasDiscount - If has discount
 * @param {boolean} isMain - If is main
 * @returns {JSX.Element}
 */
const RowComponent = ({ label, discountLabel, price, hasDiscount, isMain, icon, primary }) => {
  return (
    <PricingRow
      label={label}
      discountLabel={discountLabel}
      isMain={isMain}
      hasDiscount={hasDiscount}
      price={price}
      primary={primary}
      icon={icon}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
    />
  );
};

RowComponent.propTypes = {
  label: PropTypes.string.isRequired,
  discountLabel: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasDiscount: PropTypes.bool,
  isMain: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.bool,
};

/**
 * Component to show pricing for each passenger
 * @param {*} props - reservation, totalLabel, externalCouponCode
 * @param {*} props.reservation - reservation object
 * @param {*} props.totalLabel - total label
 * @param {*} props.externalCouponCode - external coupon code
 * @returns {React.Component} - PurchasePricingPassengerTrip component
 */
const PurchasePricingPassengerTrip = ({ reservation, totalLabel, externalCouponCode }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { tickets, pricing, tripSlug, hasConnections, hasDetails, trips = [] } = reservation;

  const discountsTypes = {
    single_trip_flat_fare_discount: 'single_trip_flat_fare_discount',
    departure_on_round_trip_flat_fare_discount: 'departure_on_round_trip_flat_fare_discount',
    single_on_round_trip_flat_fare_discount: 'single_on_round_trip_flat_fare_discount',
  };

  /**
   * Get category translation
   * @param {boolean} isAdjacentSeat
   * @param {string} category
   * @returns
   */
  const getCategoryTranslation = (isAdjacentSeat, category) => {
    const context = isAdjacentSeat ? 'adjacent_seat' : category;
    return t('category', { context });
  };

  const { total } = pricing;

  /**
   * If the whitelabel can have trips with connections
   * Then we need the trip details before being able to display the pricing
   */
  if (features.SUPPORT_TRIPS_WITH_CONNECTIONS && !hasDetails) {
    return (
      <div className="section-content">
        <LoadingDots />
      </div>
    );
  }

  return (
    <div className="section-content">
      {hasConnections
        ? trips?.map((trip) => {
            const tripTickets = tickets.filter((ticket) => ticket.tripSlug === trip.trip.id);
            const originName = trip.terminals[trip.trip.originId].name;
            const destinationName = trip.terminals[trip.trip.destinationId].name;

            return (
              <div>
                <Spacing size="XS" alignItems="center">
                  <Icon type="PinOutlined" size="S" color="grayStrong" />
                  <Text size="S" color="grayStrong" maxWidthEllipsis={120}>
                    {originName}
                  </Text>
                  <Icon type="ArrowForward" size="XS" color="grayMedium" />
                  <Text m size="S" color="grayStrong" maxWidthEllipsis={120}>
                    {destinationName}
                  </Text>
                </Spacing>
                {tripTickets.map((item) => {
                  const passengerName = `${item.firstName} ${item.lastName || ''}`;
                  const hasEarlyDiscount = item.discountReason === 'compra anticipada';
                  let discountLabels = { label: t('label.discount') };
                  if (
                    features.PURCHASE_PRICING_DETAILED_DISCOUNT &&
                    item.discountReason &&
                    item.discountPercent
                  ) {
                    discountLabels = {
                      label: t('label.your_discount_savings', {
                        reason: item.discountReason,
                      }),
                      discountLabel: t('label.maximum_discount_percentage', {
                        percent: item.discountPercent,
                      }),
                    };
                  }

                  return (
                    <Fragment key={`${tripSlug}-${item.passengerId}`}>
                      <div key={`${tripSlug}-${item.passengerId}-name`} className="passenger-row">
                        <Text weight="semibold" size="S">
                          {passengerName}
                        </Text>
                        <Gap columnGap="S">
                          <Text elementType="span" size="S" color="grayMedium">
                            {getCategoryTranslation(item.isAdjacentSeat, item.category)}
                          </Text>
                          {features.FLAT_FARE_ENABLED && discountsTypes[item.discountType] && (
                            <Text
                              elementType="span"
                              size="S"
                              color="loyaltyLight"
                              weight="semibold"
                              italic
                            >
                              {t('label.reward_ticket', { context: features.FLAT_FARE_CONTEXT })}
                            </Text>
                          )}
                        </Gap>
                      </div>
                      <RowComponent
                        key={`${tripSlug}-${item.passengerId}-price`}
                        label={t('label.price')}
                        price={item.amount}
                      />
                      {item.discountAmount > 0 &&
                        (hasEarlyDiscount ? (
                          <RowComponent
                            label={t('trips:early_discount')}
                            price={item.discountAmount}
                            hasDiscount
                            primary
                            icon={earlyDiscountIcons[env.brand] ?? earlyDiscountIcons.default}
                          />
                        ) : (
                          <RowComponent
                            label={discountLabels.label}
                            discountLabel={discountLabels.discountLabel}
                            price={item.discountAmount}
                            hasDiscount
                          />
                        ))}
                      {item.subtotal && (
                        <RowComponent
                          key={`${tripSlug}-${item.passengerId}-subtotal`}
                          label={t('label.subtotal')}
                          price={item.subtotal}
                        />
                      )}
                      <RowComponent
                        key={`${tripSlug}-${item.passengerId}-tax`}
                        label={t('extra_price')}
                        price={item.taxes}
                      />
                      {externalCouponCode && (
                        <Spacing size="S" alignItems="center">
                          <Text color="grayMedium">{t('label.coupon')}:</Text>
                          <Badge hasShadow roundCorners type="success" smallPadding>
                            <Text color="white" elementType="span" size="S" weight="semibold">
                              {externalCouponCode}
                            </Text>
                          </Badge>
                        </Spacing>
                      )}
                      <RowComponent
                        key={`${tripSlug}-${item.passengerId}-total`}
                        label={t('label.total')}
                        price={item.total}
                      />
                    </Fragment>
                  );
                })}
              </div>
            );
          })
        : null}

      {!hasConnections
        ? tickets.map((item) => {
            const passengerName = `${item.firstName} ${item.lastName || ''}`;
            const hasEarlyDiscount = item.discountReason === 'compra anticipada';
            let discountLabels = { label: t('label.discount') };
            if (
              features.PURCHASE_PRICING_DETAILED_DISCOUNT &&
              item.discountReason &&
              item.discountPercent
            ) {
              discountLabels = {
                label: t('label.your_discount_savings', {
                  reason: item.discountReason,
                }),
                discountLabel: t('label.maximum_discount_percentage', {
                  percent: item.discountPercent,
                }),
              };
            }

            return (
              <Fragment key={`${tripSlug}-${item.passengerId}`}>
                <div key={`${tripSlug}-${item.passengerId}-name`} className="passenger-row">
                  <Text weight="semibold" size="S">
                    {passengerName}
                  </Text>
                  <Gap columnGap="S">
                    <Text elementType="span" size="S" color="grayMedium">
                      {getCategoryTranslation(item.isAdjacentSeat, item.category)}
                    </Text>
                    {features.FLAT_FARE_ENABLED && discountsTypes[item.discountType] && (
                      <Text
                        elementType="span"
                        size="S"
                        color="loyaltyLight"
                        weight="semibold"
                        italic
                      >
                        {t('label.reward_ticket', { context: features.FLAT_FARE_CONTEXT })}
                      </Text>
                    )}
                  </Gap>
                </div>
                <RowComponent
                  key={`${tripSlug}-${item.passengerId}-price`}
                  label={t('label.price')}
                  price={item.amount}
                />
                {item.discountAmount > 0 &&
                  (hasEarlyDiscount ? (
                    <RowComponent
                      label={t('trips:early_discount')}
                      price={item.discountAmount}
                      hasDiscount
                      primary
                      icon={earlyDiscountIcons[env.brand] ?? earlyDiscountIcons.default}
                    />
                  ) : (
                    <RowComponent
                      label={discountLabels.label}
                      discountLabel={discountLabels.discountLabel}
                      price={item.discountAmount}
                      hasDiscount
                    />
                  ))}
                {item.subtotal && (
                  <RowComponent
                    key={`${tripSlug}-${item.passengerId}-subtotal`}
                    label={t('label.subtotal')}
                    price={item.subtotal}
                  />
                )}
                <RowComponent
                  key={`${tripSlug}-${item.passengerId}-tax`}
                  label={t('extra_price')}
                  price={item.taxes}
                />
                {externalCouponCode && (
                  <Spacing size="S" alignItems="center">
                    <Text color="grayMedium">{t('label.coupon')}:</Text>
                    <Badge hasShadow roundCorners type="success" smallPadding>
                      <Text color="white" elementType="span" size="S" weight="semibold">
                        {externalCouponCode}
                      </Text>
                    </Badge>
                  </Spacing>
                )}
                <RowComponent
                  key={`${tripSlug}-${item.passengerId}-total`}
                  label={t('label.total')}
                  price={item.total}
                />
              </Fragment>
            );
          })
        : null}

      <RowComponent label={t(`label.${totalLabel}`)} price={total} isMain />
    </div>
  );
};

PurchasePricingPassengerTrip.propTypes = propTypes;

export default PurchasePricingPassengerTrip;
