import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Gap, Icon, Spacing, Text } from '@reservamos/elements';
import './CounterSeat.scss';
import BusIconIndicator from './BusIconIndicator';

/**
 * It shows the selected seats
 * @param {*} param0 - Props
 * @param {node} param0.children - Children
 * @param {boolean} param0.hasPetFriendly - If selected seats includes a pet friendly seat
 * @param {boolean} param0.hasScale - provitional prop
 * @returns - CounterSeat component.
 */
const CounterSeat = ({
  children,
  hasPetFriendly,
  hasScale,
  firstTripIsActive,
  secondTripIsActive,
  routeLabel,
}) => {
  const { t } = useTranslation('seats');

  return (
    <div className="counter-seat">
      <Spacing vertical size="XS">
        <Gap alignItems="center" columnGap="M">
          <div>
            <Spacing size="S">
              <Text size="L" weight="bold">
                {t('pet_counter.title')}
              </Text>
              {hasScale ? (
                <Spacing size="XS">
                  <BusIconIndicator isActive={firstTripIsActive} />
                  <BusIconIndicator isActive={secondTripIsActive} />
                </Spacing>
              ) : (
                <Icon type="emojiBusSide" />
              )}
            </Spacing>
            {hasScale && <Text size="S">{routeLabel}</Text>}
          </div>

          {children}
        </Gap>

        {hasPetFriendly && (
          <Gap alignItems="center" columnGap="XXS">
            <Icon type="emojiPawsPet" size="S" />
            <Icon type="emojiPerson" size="S" />

            <Text size="S" mobileSize="XS" weight="semibold" color="grayMedium">
              {t('pet_counter.message')}
            </Text>
          </Gap>
        )}
      </Spacing>
    </div>
  );
};

CounterSeat.propTypes = {
  children: PropTypes.node.isRequired,
  hasPetFriendly: PropTypes.bool,
  hasScale: PropTypes.bool,
};

CounterSeat.defaultProps = {
  hasPetFriendly: false,
  hasScale: true,
};

export default CounterSeat;
