/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { destroy } from 'redux-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { validateExchange, resetExchange } from '@/actions/exchange';
import { executeRecaptcha } from 'utils/googleRecaptcha';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import FormLayout from './FormLayout';
import { resetPurchase } from '../../../actions/purchase';

/**
 * Component that contains the exchange form to show passing some props to it.
 * @returns Exchange Form component
 */
const ExchangeFirstStep = () => {
  const { t } = useTranslation('exchange');
  const { type: exchangeType } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.whitelabelConfig);
  const env = useWhitelabelEnvs();
  const usingRecaptcha = features.EXCHANGE_REQUIRE_RECAPTCHA && env.recaptcha;
  /**
   * Function to handle the recaptcha token
   */
  const onCaptchaVerify = (onRecaptchaToken) => {
    const action = features.EXCHANGE_RECAPTCHA_ACTION;
    executeRecaptcha(action, (recaptchaToken) => {
      onRecaptchaToken(recaptchaToken);
    });
  };
  useEffect(() => {
    dispatch(resetExchange());
    dispatch(resetPurchase());
    dispatch(destroy('purchaser'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // It can be 2 types of exchange, itinerary exchange or an open ticket exchange
  const exchangeTitle =
    exchangeType === 'itinerary' ? t('title.modify_itinerary') : t('title.exchange_open_ticket');
  const exchangeTextCTA = exchangeType === 'itinerary' ? t('button.modify') : t('button.exchange');
  const exchangeRedirectText =
    exchangeType === 'itinerary' ? t('button.want_exchange') : t('button.want_modify');
  const addTicketsTitle =
    exchangeType === 'itinerary' ? t('labels.add_ticket') : t('labels.add_ticket_open_ticket');

  const exchangeTypesAllowed = features.EXCHANGE_TYPES;
  if (!exchangeTypesAllowed.includes(exchangeType)) {
    history.replace(`/exchange/${exchangeTypesAllowed[0]}`);
  }

  const generalInitialValues = {
    operationNumber: '',
  };
  const generalValidationScheme = {
    operationNumber: yup
      .string()
      .matches(/^[0-9]+$/, t('validation.only_numbers'))
      .min(8, t('validation.min_digits', { digits: 8 }))
      .max(12, t('validation.max_digits', { digits: 12 }))
      .required(t('validation.operation_number_required')),
  };

  /**
   * Function to handle the submit of the exchange form
   * @param {String} values - Values of the form to be used for the exchange
   */
  const handleSubmit = (values) => {
    const { email, document, operationNumbers, nit, originSlug, destinationSlug } = values;

    /**
     * Function to validate the exchange wrapping the recaptcha token if needed
     * @param {*} [recaptchaToken] - Recaptcha token
     * @returns {function} - Dispatch function
     */
    const validationFunction = (recaptchaToken) =>
      dispatch(
        validateExchange(
          history,
          operationNumbers,
          nit,
          originSlug,
          destinationSlug,
          recaptchaToken,
          email,
          document,
        ),
      );

    if (usingRecaptcha) {
      onCaptchaVerify(validationFunction);
    } else {
      validationFunction();
    }
  };

  const handleRedirectExchangeAlternative = () => {
    history.push(`/exchange/${exchangeType === 'itinerary' ? 'openTicket' : 'ticket'}`);
  };

  return (
    <FormLayout
      title={exchangeTitle}
      ctaText={exchangeTextCTA}
      redirectText={exchangeRedirectText}
      addTicketsTitle={addTicketsTitle}
      handleSubmit={handleSubmit}
      generalInitialValues={generalInitialValues}
      generalValidationScheme={generalValidationScheme}
      handleRedirectExchangeAlternative={handleRedirectExchangeAlternative}
      showRedirectButton={exchangeTypesAllowed.length > 1}
    />
  );
};

export default ExchangeFirstStep;
