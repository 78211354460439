import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Toggle, Visibility } from '@reservamos/elements';
import SeatSelector from 'components/purchase/SeatSelector';
import usePurchase from 'hooks/store/usePurchase';
import { getSeatSelectorPropsFromPurchase } from 'utils/seats';

const MultiTripSeatSelector = ({ way, selectSeats, finishSeatSelection }) => {
  const purchase = usePurchase();
  const { t } = useTranslation('seats');
  const {
    departs: {
      layouts: departureLayouts = {},
      seats: departureSeats = {},
      trips: departureTripsState = [],
      tickets: departureTickets = [],
    },
    returns: {
      layouts: returnLayouts = {},
      seats: returnSeats = {},
      trips: returnTripsState = [],
      tickets: returnTickets = [],
    },
  } = purchase;
  const seats = way === 'departure' ? departureSeats : returnSeats;
  const layouts = way === 'departure' ? departureLayouts : returnLayouts;
  const lockedSeats = way === 'departure' ? departureTickets : returnTickets;
  const tripsState = way === 'departure' ? departureTripsState : returnTripsState;
  const trips = tripsState.map((trip) => ({ ...trip, id: trip.trip.id }));
  const [currentTrip, setCurrentTrip] = useState(trips[0]);
  const {
    sendNotification,
    updateSeat,
    isExchange,
    busCategories,
    isLoading,
    allowsSeatSelection,
    passengers,
    diagramType,
    isLockingTickets = false,
    fetchingDetails = true,
    selectedSeats = [],
    isOpenTicket,
    canGoBack,
    tripPricing,
    hasSeats,
    resultsLayout,
    onClose,
    clearError,
    fetchingDetailsError,
    minPassengers,
  } = getSeatSelectorPropsFromPurchase({ way, purchase });
  const loading = !allowsSeatSelection || isLockingTickets || !hasSeats || isLoading;

  const onSelectTripSeats = (tripSlug) => (tripSeats) => {
    const newSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug !== tripSlug);
    newSelectedSeats.push(...tripSeats);
    selectSeats(newSelectedSeats);
  };

  const firstTripSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug === trips[0].id);
  const secondTripSelectedSeats = selectedSeats.filter((seat) => seat.tripSlug === trips[1].id);
  const firstTripHasSelectedSeats = firstTripSelectedSeats.length > 0;
  const secondTripHasSelectedSeats = secondTripSelectedSeats.length > 0;
  const secondTripMinPassengers = firstTripSelectedSeats.length;

  const firstTrip = trips[0];
  const firstTripOriginName = firstTrip.terminals[firstTrip.trip.originId].name;
  const firstTripDestinationName = firstTrip.terminals[firstTrip.trip.destinationId].name;
  const firstTripRouteLabel = `${firstTripOriginName} - ${firstTripDestinationName}`;

  const secondTrip = trips[1];
  const secondTripOriginName = secondTrip.terminals[secondTrip.trip.originId].name;
  const secondTripDestinationName = secondTrip.terminals[secondTrip.trip.destinationId].name;
  const secondTripRouteLabel = `${secondTripOriginName} - ${secondTripDestinationName}`;

  const onFinishSeatSelection =
    (isFirstTrip = false) =>
    () => {
      if (isFirstTrip && firstTripHasSelectedSeats) {
        const invalidSecondTripSeatsLength =
          secondTripSelectedSeats.length > firstTripSelectedSeats.length;
        if (invalidSecondTripSeatsLength) {
          onSelectTripSeats(trips[1].id)(
            secondTripSelectedSeats.slice(0, firstTripSelectedSeats.length),
          );
        }
        setCurrentTrip(trips[1]);
        return;
      }
      if (firstTripHasSelectedSeats && secondTripHasSelectedSeats) {
        finishSeatSelection();
      }
    };

  const onToggleCurrentTrip = () => {
    const firstTripIsActive = currentTrip.id === trips[0].id;
    if (firstTripIsActive) {
      onFinishSeatSelection(true)();
      return;
    }
    setCurrentTrip(trips[0]);
  };

  const firstTripIsActive = currentTrip.id === trips[0].id;
  const secondTripIsActive = currentTrip.id === trips[1].id;

  return (
    <Spacing vertical size="M">
      <Toggle
        isChecked={secondTripIsActive}
        onClick={onToggleCurrentTrip}
        fullWidth
        alphaBg="S"
        isGradient={false}
        firstContent={
          <Spacing size="S" isResponsive>
            <Text size="S" weight="bold" color={firstTripIsActive ? 'grayStrong' : 'grayLight'}>
              Primerio Trecho
            </Text>
            <Visibility type="hideForMedium">
              <Text color={firstTripIsActive ? 'grayStrong' : 'grayLight'} size="S">
                {firstTripRouteLabel}
              </Text>
            </Visibility>
          </Spacing>
        }
        secondContent={
          <Spacing size="S" isResponsive>
            <Text size="S" weight="bold" color={secondTripIsActive ? 'grayStrong' : 'grayLight'}>
              Segundo Trecho
            </Text>
            <Visibility type="hideForMedium">
              <Text color={secondTripIsActive ? 'grayStrong' : 'grayLight'} size="S">
                {secondTripRouteLabel}
              </Text>
            </Visibility>
          </Spacing>
        }
      />
      {trips.map((trip, index) => {
        const selectedTripSeats = selectedSeats.filter((seat) => seat.tripSlug === trip.id);
        const tripLockedSeats = lockedSeats.filter((seat) => seat.tripSlug === trip.id);
        const tripIsActive = currentTrip.id === trip.id;
        const isFirstTrip = index === 0;
        const originName = trip.terminals[trip.trip.originId].name;
        const destinationName = trip.terminals[trip.trip.destinationId].name;
        const routeLabel = `${originName} - ${destinationName}`;
        let tripMinPassengers = minPassengers;
        if (way === 'departure' && !isFirstTrip) {
          tripMinPassengers = secondTripMinPassengers;
        }

        return tripIsActive ? (
          <SeatSelector
            isMultiTrip
            firstTripIsActive={isFirstTrip && tripIsActive}
            secondTripIsActive={!isFirstTrip && tripIsActive}
            routeLabel={routeLabel}
            headerLegend={
              isFirstTrip ? t('your_seats_first_connection') : t('your_seats_second_connection')
            }
            key={trip.id}
            way={way}
            tripId={trip.id}
            layout={layouts[trip.id]}
            seats={seats[trip.id]}
            trip={trip}
            allowsSeatSelection={allowsSeatSelection}
            diagramType={diagramType}
            fetchingDetails={fetchingDetails}
            isPetFriendly={false}
            lockedSeats={tripLockedSeats}
            selectedSeats={selectedTripSeats}
            selectSeats={onSelectTripSeats(trip.id)}
            onFinishedSelection={onFinishSeatSelection(isFirstTrip)}
            sendNotification={sendNotification}
            isOpenTicket={isOpenTicket}
            isExchange={isExchange}
            passengers={isExchange && passengers}
            busCategories={busCategories}
            minPassengers={tripMinPassengers}
            updateSeat={updateSeat}
            isLoading={loading}
            canGoBack={canGoBack}
            tripPricing={tripPricing}
            resultsLayout={resultsLayout}
            onClose={onClose}
            hasSeats={hasSeats}
            clearError={clearError}
            fetchingDetailsError={fetchingDetailsError}
          />
        ) : null;
      })}
    </Spacing>
  );
};

export default MultiTripSeatSelector;
