import { connect } from 'react-redux';
import { EXCHANGE_TYPEAHEAD_CLEAR, EXCHANGE_DATEPICKER_CHANGE } from 'constants/ActionTypes';
import {
  cancelTicket,
  getCancelledTicketStatusPoll,
  errorTripExchange,
  validateExchange,
} from '../../../actions/exchange';

import ExchangeTicketList from './ExchangeTicketList';

const mapStateToProps = ({ purchase, exchange, code }) => {
  const { passengers, allPassengers, selectedPassengerToAlter } = exchange;

  const { purchaseToken, idCancellation, cancelStatus } = exchange;
  const { trip, tripDepart, tripReturn, showCancelModal } = exchange;
  const { origin, destination } = exchange.ticket;

  const currentTicket = exchange.ticket.operationNumber;

  const { errorTrip } = exchange;
  return {
    isFetching: purchase.get('isFetching'),
    token: purchase.get('token'),
    currentTicket,
    code,
    errorTrip,
    passengers,
    allPassengers,
    selectedPassengerToAlter,
    trip,
    tripReturn,
    origin,
    destination,
    tripDepart,
    purchaseToken,
    idCancellation,
    cancelStatus,
    showCancelModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch({ type: EXCHANGE_DATEPICKER_CHANGE, date }),
    clearTypeahead: (origin, destination) =>
      dispatch({
        type: EXCHANGE_TYPEAHEAD_CLEAR,
        origin,
        destination,
      }),
    errorTripExchange: (errorTrip) => dispatch(errorTripExchange(errorTrip)),
    cancelTicket: (purchaseToken, seat) => dispatch(cancelTicket(purchaseToken, seat)),
    getCancelledTicketStatusPoll: (purchaseToken, idCancellation) =>
      dispatch(getCancelledTicketStatusPoll(purchaseToken, idCancellation)),
    validateExchange: (
      history,
      operationNumbers,
      NIT,
      origin,
      destination,
      recaptchaToken,
      email,
      document,
    ) =>
      dispatch(
        validateExchange(
          history,
          operationNumbers,
          NIT,
          origin,
          destination,
          recaptchaToken,
          email,
          document,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeTicketList);
