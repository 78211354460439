// return an object collection of the seats for a bus layout
const getSeats = (layout, tripSlug) => {
  const seats = {};
  const categories = [];
  layout
    .flat(2)
    .filter((item) => item.number)
    .forEach((item) => {
      seats[item.number] = { ...item, tripSlug };
      if (categories.indexOf(item.category) === -1) categories.push(item.category);
    });
  seats.categories = categories;
  return seats;
};

export default getSeats;
