import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Spacing, Input, Button, Text } from '@reservamos/elements';
import SectionTitle from 'ui/atoms/SectionTitle';
import ExchangeInfoModal from 'ui/molecules/ExchangeInfoModal';

/**
 * SNForm Functional Component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleSubmit - The function to be called when the form is submitted.
 * @param {string} props.ctaText - The text to be displayed on the call-to-action button.
 *
 * @returns {JSX.Element} The rendered form component.
 */
const SNForm = ({ handleSubmit, ctaText }) => {
  const { t } = useTranslation('exchange');
  const initialValues = {
    operationNumber: '',
    document: '',
    email: '',
  };

  const validationSchema = yup.object().shape({
    operationNumber: yup.string().required(''),
    document: yup.string().required(''),
    email: yup.string().required(''),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSubmit({
          operationNumbers: [values.operationNumber],
          document: values.document,
          email: values.email,
        })
      }
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Spacing vertical responsiveSize="XL">
            <SectionTitle
              title={t('title.exchange_cancelations')}
              description={t('subtitle.exchange_cancelations.subtitle')}
            />
            <ul className="list-disc list-inside">
              <li className="text-base">{t('subtitle.exchange_cancelations.1st')}</li>
              <li className="text-base">{t('subtitle.exchange_cancelations.2nd')}</li>
              <li className="text-base">{t('subtitle.exchange_cancelations.3rd')}</li>
            </ul>
            <Spacing flexGrow isResponsive responsiveSize="S">
              <Input
                autocomplete="off"
                id="operationNumber"
                name="operationNumber"
                label={t('labels.ticket_number')}
                maxLength={10}
                errorMessage={errors.operationNumber}
                hasError={errors.operationNumber !== undefined && touched.operationNumber}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.operationNumber}
              />
              <Input
                id="document"
                name="document"
                label={t('labels.rg')}
                maxLength={15}
                errorMessage={errors.document}
                hasError={errors.document !== undefined && touched.document}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.document}
              />
              <Input
                id="email"
                name="email"
                label={t('labels.email')}
                maxLength={40}
                errorMessage={errors.email}
                hasError={errors.email !== undefined && touched.email}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
            </Spacing>
            <ExchangeInfoModal />
            <Spacing justifyContent="space-between" isResponsive>
              <Spacing size="XS" vertical>
                <Text weight="bold"> {t('disclaimer.satelite_norte.first')} </Text>
                <Text weight="bold"> {t('disclaimer.satelite_norte.second')} </Text>
                <Text weight="bold"> {t('disclaimer.satelite_norte.third')} </Text>
              </Spacing>
              <Spacing isResponsive responsiveColumnReverse>
                <Button
                  buttonType="submit"
                  text={ctaText}
                  variant="accent"
                  isRounded
                  withHeight
                  fullWidthOnSmall
                />
              </Spacing>
            </Spacing>
          </Spacing>
        </Form>
      )}
    </Formik>
  );
};

const propTypes = {
  handleSubmit: PropTypes.func,
  ctaText: PropTypes.string,
};

SNForm.propTypes = propTypes;

export default SNForm;
